import liveswitch from "fm.liveswitch";
import { useEffect, useState } from "react";
import ChannelConnection from "../../js/connection/ChannelConnection";
import ClientOverlay from "./ClientOverlay";

type Props = {
    connection: ChannelConnection;
};

export default function MCULayoutOverlay({ connection }: Props) {
    const [overlays, setOverlays] = useState<OverlayData[]>([]);
    useEffect(() => {
        function resize({ videoDom, layout }: { videoDom: HTMLElement | null, layout: liveswitch.VideoLayout }): void {            
            let heightOffset = 0;
            let widthOffset = 0;
            let scaling = 1;
        
            // Get display area dimensions
            let dWidth = videoDom!.clientWidth;
            let dHeight = videoDom!.clientHeight;
            let dAspectRatio = dWidth / dHeight;
        
            // Get MCU video content dimensions
            let vWidth = layout.getWidth();
            let vHeight = layout.getHeight();
            let vAspectRatio = vWidth / vHeight;
        
            if (vAspectRatio > dAspectRatio) {
                scaling = dWidth / vWidth;
                heightOffset = (dHeight - vHeight * scaling) / 2;
            } else {
                scaling = dHeight / vHeight;
                widthOffset = (dWidth - vWidth * scaling) / 2;
            }

            const data: OverlayData[] = [];
    
            // Do MCU name overlays
            layout.getRegions().forEach(region => {
                let frame = region.getFrame();
                let bounds = region.getBounds();
                const top = heightOffset + scaling * (frame.getY() + bounds.getY());
                const left = widthOffset + scaling * (frame.getX() + bounds.getX());
                const right = scaling * (frame.getX() + bounds.getX())+10;
                const bottom = scaling * (frame.getY() + bounds.getY())+10;

                const connectionId = region.getConnectionId();
                const userId = region.getUserId();
                const userAlias = region.getUserAlias();
                const roles = region.getClientRoles();

                data.push({ connectionId, userId, userAlias, roles, top, left, right, bottom });
            });

            setOverlays(data);
        }

        connection.events.addListener("onvideolayout", resize);

        return () => {
            connection.events.removeListener("onvideolayout", resize);
        }

    }, [connection.events]);

    function onScreenshotRequest(holoLensId: string): void {
        connection.messager.requestScreenshot(holoLensId).then((videoFrameData: VideoFrameData) => {
            connection.events.notify("onscreenshot", { userId: holoLensId, videoFrameData });
        }).catch(ex => console.log(ex));
    }

    function onUploadClick(holoLensId: string): void {
        connection.events.notify("onscreenshot", { userId: holoLensId, videoFrameData: null });
    }

    return (
        <div style={{ zIndex: 1 }}>
            {overlays.map(o => <ClientOverlay key={o.connectionId} overlay={o} onScreenshotClick={onScreenshotRequest} onUploadClick={onUploadClick} />)}
        </div>
    );
}

export interface OverlayData {
    connectionId: string;
    userId: string;
    userAlias: string;
    roles: string[] | undefined;
    top: number;
    left: number;
    right: number;
    bottom: number;
}